<div class="card">
  <slot></slot>
</div>

<style>
  .card{
    background: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(0,0,0, 0.1);
    margin: 20px;
    opacity: 1;
  }
</style>